import _javadoclike from "./javadoclike.js";
var exports = {};
var refractorJavadoclike = _javadoclike;
exports = jsdoc;
jsdoc.displayName = "jsdoc";
jsdoc.aliases = [];

function jsdoc(Prism) {
  Prism.register(refractorJavadoclike);

  (function (Prism) {
    var javascript = Prism.languages.javascript;
    var type = /{(?:[^{}]|{(?:[^{}]|{[^{}]*})*})+}/.source;
    var parameterPrefix = "(@(?:param|arg|argument|property)\\s+(?:" + type + "\\s+)?)";
    Prism.languages.jsdoc = Prism.languages.extend("javadoclike", {
      parameter: {
        // @param {string} foo - foo bar
        pattern: RegExp(parameterPrefix + /[$\w\xA0-\uFFFF.]+(?=\s|$)/.source),
        lookbehind: true,
        inside: {
          punctuation: /\./
        }
      }
    });
    Prism.languages.insertBefore("jsdoc", "keyword", {
      "optional-parameter": {
        // @param {string} [baz.foo="bar"] foo bar
        pattern: RegExp(parameterPrefix + /\[[$\w\xA0-\uFFFF.]+(?:=[^[\]]+)?\](?=\s|$)/.source),
        lookbehind: true,
        inside: {
          parameter: {
            pattern: /(^\[)[$\w\xA0-\uFFFF\.]+/,
            lookbehind: true,
            inside: {
              punctuation: /\./
            }
          },
          code: {
            pattern: /(=)[\s\S]*(?=\]$)/,
            lookbehind: true,
            inside: javascript,
            alias: "language-javascript"
          },
          punctuation: /[=[\]]/
        }
      },
      "class-name": [{
        pattern: RegExp("(@[a-z]+\\s+)" + type),
        lookbehind: true,
        inside: {
          punctuation: /[.,:?=<>|{}()[\]]/
        }
      }, {
        pattern: /(@(?:augments|extends|class|interface|memberof!?|this)\s+)[A-Z]\w*(?:\.[A-Z]\w*)*/,
        lookbehind: true,
        inside: {
          punctuation: /\./
        }
      }],
      example: {
        pattern: /(@example\s+)[^@]+?(?=\s*(?:\*\s*)?(?:@\w|\*\/))/,
        lookbehind: true,
        inside: {
          code: {
            pattern: /^(\s*(?:\*\s*)?).+$/m,
            lookbehind: true,
            inside: javascript,
            alias: "language-javascript"
          }
        }
      }
    });
    Prism.languages.javadoclike.addSupport("javascript", Prism.languages.jsdoc);
  })(Prism);
}

export default exports;