import _core from "./core.js";
import _abap from "./lang/abap.js";
import _abnf from "./lang/abnf.js";
import _actionscript from "./lang/actionscript.js";
import _ada from "./lang/ada.js";
import _apacheconf from "./lang/apacheconf.js";
import _apl from "./lang/apl.js";
import _applescript from "./lang/applescript.js";
import _arduino from "./lang/arduino.js";
import _arff from "./lang/arff.js";
import _asciidoc from "./lang/asciidoc.js";
import _asm from "./lang/asm6502.js";
import _aspnet from "./lang/aspnet.js";
import _autohotkey from "./lang/autohotkey.js";
import _autoit from "./lang/autoit.js";
import _bash from "./lang/bash.js";
import _basic from "./lang/basic.js";
import _batch from "./lang/batch.js";
import _bison from "./lang/bison.js";
import _bnf from "./lang/bnf.js";
import _brainfuck from "./lang/brainfuck.js";
import _bro from "./lang/bro.js";
import _c from "./lang/c.js";
import _cil from "./lang/cil.js";
import _clojure from "./lang/clojure.js";
import _cmake from "./lang/cmake.js";
import _coffeescript from "./lang/coffeescript.js";
import _cpp from "./lang/cpp.js";
import _crystal from "./lang/crystal.js";
import _csharp from "./lang/csharp.js";
import _csp from "./lang/csp.js";
import _cssExtras from "./lang/css-extras.js";
import _d from "./lang/d.js";
import _dart from "./lang/dart.js";
import _diff from "./lang/diff.js";
import _django from "./lang/django.js";
import _dnsZoneFile from "./lang/dns-zone-file.js";
import _docker from "./lang/docker.js";
import _ebnf from "./lang/ebnf.js";
import _eiffel from "./lang/eiffel.js";
import _ejs from "./lang/ejs.js";
import _elixir from "./lang/elixir.js";
import _elm from "./lang/elm.js";
import _erb from "./lang/erb.js";
import _erlang from "./lang/erlang.js";
import _flow from "./lang/flow.js";
import _fortran from "./lang/fortran.js";
import _fsharp from "./lang/fsharp.js";
import _gcode from "./lang/gcode.js";
import _gedcom from "./lang/gedcom.js";
import _gherkin from "./lang/gherkin.js";
import _git from "./lang/git.js";
import _glsl from "./lang/glsl.js";
import _gml from "./lang/gml.js";
import _go from "./lang/go.js";
import _graphql from "./lang/graphql.js";
import _groovy from "./lang/groovy.js";
import _haml from "./lang/haml.js";
import _handlebars from "./lang/handlebars.js";
import _haskell from "./lang/haskell.js";
import _haxe from "./lang/haxe.js";
import _hcl from "./lang/hcl.js";
import _hpkp from "./lang/hpkp.js";
import _hsts from "./lang/hsts.js";
import _http from "./lang/http.js";
import _ichigojam from "./lang/ichigojam.js";
import _icon from "./lang/icon.js";
import _inform from "./lang/inform7.js";
import _ini from "./lang/ini.js";
import _io from "./lang/io.js";
import _j from "./lang/j.js";
import _java from "./lang/java.js";
import _javadoc from "./lang/javadoc.js";
import _javadoclike from "./lang/javadoclike.js";
import _javastacktrace from "./lang/javastacktrace.js";
import _jolie from "./lang/jolie.js";
import _jq from "./lang/jq.js";
import _jsExtras from "./lang/js-extras.js";
import _jsTemplates from "./lang/js-templates.js";
import _jsdoc from "./lang/jsdoc.js";
import _json from "./lang/json.js";
import _json2 from "./lang/json5.js";
import _jsonp from "./lang/jsonp.js";
import _jsx from "./lang/jsx.js";
import _julia from "./lang/julia.js";
import _keyman from "./lang/keyman.js";
import _kotlin from "./lang/kotlin.js";
import _latex from "./lang/latex.js";
import _less from "./lang/less.js";
import _lilypond from "./lang/lilypond.js";
import _liquid from "./lang/liquid.js";
import _lisp from "./lang/lisp.js";
import _livescript from "./lang/livescript.js";
import _lolcode from "./lang/lolcode.js";
import _lua from "./lang/lua.js";
import _makefile from "./lang/makefile.js";
import _markdown from "./lang/markdown.js";
import _markupTemplating from "./lang/markup-templating.js";
import _matlab from "./lang/matlab.js";
import _mel from "./lang/mel.js";
import _mizar from "./lang/mizar.js";
import _monkey from "./lang/monkey.js";
import _n1ql from "./lang/n1ql.js";
import _n4js from "./lang/n4js.js";
import _nand2tetrisHdl from "./lang/nand2tetris-hdl.js";
import _nasm from "./lang/nasm.js";
import _nginx from "./lang/nginx.js";
import _nim from "./lang/nim.js";
import _nix from "./lang/nix.js";
import _nsis from "./lang/nsis.js";
import _objectivec from "./lang/objectivec.js";
import _ocaml from "./lang/ocaml.js";
import _opencl from "./lang/opencl.js";
import _oz from "./lang/oz.js";
import _parigp from "./lang/parigp.js";
import _parser from "./lang/parser.js";
import _pascal from "./lang/pascal.js";
import _pascaligo from "./lang/pascaligo.js";
import _pcaxis from "./lang/pcaxis.js";
import _perl from "./lang/perl.js";
import _phpExtras from "./lang/php-extras.js";
import _php from "./lang/php.js";
import _phpdoc from "./lang/phpdoc.js";
import _plsql from "./lang/plsql.js";
import _powershell from "./lang/powershell.js";
import _processing from "./lang/processing.js";
import _prolog from "./lang/prolog.js";
import _properties from "./lang/properties.js";
import _protobuf from "./lang/protobuf.js";
import _pug from "./lang/pug.js";
import _puppet from "./lang/puppet.js";
import _pure from "./lang/pure.js";
import _python from "./lang/python.js";
import _q from "./lang/q.js";
import _qore from "./lang/qore.js";
import _r from "./lang/r.js";
import _reason from "./lang/reason.js";
import _regex from "./lang/regex.js";
import _renpy from "./lang/renpy.js";
import _rest from "./lang/rest.js";
import _rip from "./lang/rip.js";
import _roboconf from "./lang/roboconf.js";
import _ruby from "./lang/ruby.js";
import _rust from "./lang/rust.js";
import _sas from "./lang/sas.js";
import _sass from "./lang/sass.js";
import _scala from "./lang/scala.js";
import _scheme from "./lang/scheme.js";
import _scss from "./lang/scss.js";
import _shellSession from "./lang/shell-session.js";
import _smalltalk from "./lang/smalltalk.js";
import _smarty from "./lang/smarty.js";
import _soy from "./lang/soy.js";
import _splunkSpl from "./lang/splunk-spl.js";
import _sql from "./lang/sql.js";
import _stylus from "./lang/stylus.js";
import _swift from "./lang/swift.js";
import _t4Cs from "./lang/t4-cs.js";
import _t4Templating from "./lang/t4-templating.js";
import _t4Vb from "./lang/t4-vb.js";
import _tap from "./lang/tap.js";
import _tcl from "./lang/tcl.js";
import _textile from "./lang/textile.js";
import _toml from "./lang/toml.js";
import _tsx from "./lang/tsx.js";
import _tt from "./lang/tt2.js";
import _twig from "./lang/twig.js";
import _typescript from "./lang/typescript.js";
import _vala from "./lang/vala.js";
import _vbnet from "./lang/vbnet.js";
import _velocity from "./lang/velocity.js";
import _verilog from "./lang/verilog.js";
import _vhdl from "./lang/vhdl.js";
import _vim from "./lang/vim.js";
import _visualBasic from "./lang/visual-basic.js";
import _wasm from "./lang/wasm.js";
import _wiki from "./lang/wiki.js";
import _xeora from "./lang/xeora.js";
import _xojo from "./lang/xojo.js";
import _xquery from "./lang/xquery.js";
import _yaml from "./lang/yaml.js";
var exports = {};
var refractor = _core;
exports = refractor;
refractor.register(_abap);
refractor.register(_abnf);
refractor.register(_actionscript);
refractor.register(_ada);
refractor.register(_apacheconf);
refractor.register(_apl);
refractor.register(_applescript);
refractor.register(_arduino);
refractor.register(_arff);
refractor.register(_asciidoc);
refractor.register(_asm);
refractor.register(_aspnet);
refractor.register(_autohotkey);
refractor.register(_autoit);
refractor.register(_bash);
refractor.register(_basic);
refractor.register(_batch);
refractor.register(_bison);
refractor.register(_bnf);
refractor.register(_brainfuck);
refractor.register(_bro);
refractor.register(_c);
refractor.register(_cil);
refractor.register(_clojure);
refractor.register(_cmake);
refractor.register(_coffeescript);
refractor.register(_cpp);
refractor.register(_crystal);
refractor.register(_csharp);
refractor.register(_csp);
refractor.register(_cssExtras);
refractor.register(_d);
refractor.register(_dart);
refractor.register(_diff);
refractor.register(_django);
refractor.register(_dnsZoneFile);
refractor.register(_docker);
refractor.register(_ebnf);
refractor.register(_eiffel);
refractor.register(_ejs);
refractor.register(_elixir);
refractor.register(_elm);
refractor.register(_erb);
refractor.register(_erlang);
refractor.register(_flow);
refractor.register(_fortran);
refractor.register(_fsharp);
refractor.register(_gcode);
refractor.register(_gedcom);
refractor.register(_gherkin);
refractor.register(_git);
refractor.register(_glsl);
refractor.register(_gml);
refractor.register(_go);
refractor.register(_graphql);
refractor.register(_groovy);
refractor.register(_haml);
refractor.register(_handlebars);
refractor.register(_haskell);
refractor.register(_haxe);
refractor.register(_hcl);
refractor.register(_hpkp);
refractor.register(_hsts);
refractor.register(_http);
refractor.register(_ichigojam);
refractor.register(_icon);
refractor.register(_inform);
refractor.register(_ini);
refractor.register(_io);
refractor.register(_j);
refractor.register(_java);
refractor.register(_javadoc);
refractor.register(_javadoclike);
refractor.register(_javastacktrace);
refractor.register(_jolie);
refractor.register(_jq);
refractor.register(_jsExtras);
refractor.register(_jsTemplates);
refractor.register(_jsdoc);
refractor.register(_json);
refractor.register(_json2);
refractor.register(_jsonp);
refractor.register(_jsx);
refractor.register(_julia);
refractor.register(_keyman);
refractor.register(_kotlin);
refractor.register(_latex);
refractor.register(_less);
refractor.register(_lilypond);
refractor.register(_liquid);
refractor.register(_lisp);
refractor.register(_livescript);
refractor.register(_lolcode);
refractor.register(_lua);
refractor.register(_makefile);
refractor.register(_markdown);
refractor.register(_markupTemplating);
refractor.register(_matlab);
refractor.register(_mel);
refractor.register(_mizar);
refractor.register(_monkey);
refractor.register(_n1ql);
refractor.register(_n4js);
refractor.register(_nand2tetrisHdl);
refractor.register(_nasm);
refractor.register(_nginx);
refractor.register(_nim);
refractor.register(_nix);
refractor.register(_nsis);
refractor.register(_objectivec);
refractor.register(_ocaml);
refractor.register(_opencl);
refractor.register(_oz);
refractor.register(_parigp);
refractor.register(_parser);
refractor.register(_pascal);
refractor.register(_pascaligo);
refractor.register(_pcaxis);
refractor.register(_perl);
refractor.register(_phpExtras);
refractor.register(_php);
refractor.register(_phpdoc);
refractor.register(_plsql);
refractor.register(_powershell);
refractor.register(_processing);
refractor.register(_prolog);
refractor.register(_properties);
refractor.register(_protobuf);
refractor.register(_pug);
refractor.register(_puppet);
refractor.register(_pure);
refractor.register(_python);
refractor.register(_q);
refractor.register(_qore);
refractor.register(_r);
refractor.register(_reason);
refractor.register(_regex);
refractor.register(_renpy);
refractor.register(_rest);
refractor.register(_rip);
refractor.register(_roboconf);
refractor.register(_ruby);
refractor.register(_rust);
refractor.register(_sas);
refractor.register(_sass);
refractor.register(_scala);
refractor.register(_scheme);
refractor.register(_scss);
refractor.register(_shellSession);
refractor.register(_smalltalk);
refractor.register(_smarty);
refractor.register(_soy);
refractor.register(_splunkSpl);
refractor.register(_sql);
refractor.register(_stylus);
refractor.register(_swift);
refractor.register(_t4Cs);
refractor.register(_t4Templating);
refractor.register(_t4Vb);
refractor.register(_tap);
refractor.register(_tcl);
refractor.register(_textile);
refractor.register(_toml);
refractor.register(_tsx);
refractor.register(_tt);
refractor.register(_twig);
refractor.register(_typescript);
refractor.register(_vala);
refractor.register(_vbnet);
refractor.register(_velocity);
refractor.register(_verilog);
refractor.register(_vhdl);
refractor.register(_vim);
refractor.register(_visualBasic);
refractor.register(_wasm);
refractor.register(_wiki);
refractor.register(_xeora);
refractor.register(_xojo);
refractor.register(_xquery);
refractor.register(_yaml);
export default exports;